import axios from 'axios';
export const SERVER_ADDRESS="https://core.emailwish.com";
export const client_uid="5e2020e05a9a2";

export interface iApiBasicResponse {
    statusCode: number
    message?: string | object
    redirectURL?: string
    redirectAction?: string
    view?: string
    errors?: {
        [key: string]: string | Array<string>
    }
    uid?: string
}

export  interface ChatSession extends iApiBasicResponse{
    user_id?:number
    customer_id?:number
    name?:string
    email?:string
    id?:string,
    secret_key?:string,
    updated_at?:Date
    created_at?:Date
}
export interface ChatMessage {
    id: number,
    customer_id: string,
    user_id: string,
    session_id: string,
    from_guest: string,
    message: string,
}

export interface responseMessage extends iApiBasicResponse{
    messages: ChatMessage[];
}
export default class ChatAPIs{
    static hasError = (response: iApiBasicResponse) => {
        if (response.statusCode >= 400) return true;
        if (response.errors) return true;
    };
    create_session = async (email:string, username:string): Promise<ChatSession> => {
        let fd = new FormData();
        fd.append("email", email);
        fd.append("name",username);
        fd.append("client_uid",client_uid);
        return axios
            .post(SERVER_ADDRESS+"/_shopify/chat/sessions/new", fd, {
                withCredentials: true,
            })
            .then((res): ChatSession =>
                ({statusCode: res.status, ...res.data}))
            .catch((): ChatSession =>
                ({statusCode: 999, errors: {network: "Network error!"}}))
    };

    fetch_session = async (): Promise<ChatSession> => {
        return axios
            .get(SERVER_ADDRESS+"/_shopify/chat/sessions/get", {
                withCredentials: true,
            })
            .then((res): ChatSession =>
                ({statusCode: res.status, ...res.data}))
            .catch((): ChatSession =>
                ({statusCode: 999, errors: {network: "Network error!"}}))
    };

    store_message = async (session_id:string,secret_key:string,message:string): Promise<iApiBasicResponse> => {
        let fd = new FormData();
        fd.append("session_id", session_id);
        fd.append("secret_key",secret_key);
        fd.append("message",message);
        return axios
            .post(SERVER_ADDRESS+"/_shopify/chat/messages/storeGuest", fd, {
                withCredentials: true,
            })
            .then((res): iApiBasicResponse =>
                ({statusCode: res.status, ...res.data}))
            .catch((): iApiBasicResponse =>
                ({statusCode: 999, errors: {network: "Network error!"}}))
    };

    list_guest = async (session_id:string,secret_key:string,last_id?:any): Promise<responseMessage> => {
        let fd = new FormData();
        fd.append("session_id", session_id);
        fd.append("secret_key",secret_key);
        if(last_id)
        fd.append("last_id",last_id.toString());
        return axios
            .post(SERVER_ADDRESS+"/_shopify/chat/messages/listGuest", fd, {
                withCredentials: true,
            })
            .then((res): any => {
                return  ({statusCode: res.status, ...res.data})
                }
            )
            .catch((err:any): any => {
                    return ({statusCode: 999, errors: {network: "Network error!"}})
                }
            )
    };
}
