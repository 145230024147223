import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {addResponseMessage, Widget} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import ChatAPIs from './API/apis';


function App() {
    const [session_id, setSessionID] = useState<string>("");
    const [secret_key, setSecretKey] = useState<string>("");
    const [last_id, setLastId] = useState<number | null>(null);
    const [email, setEmail] = useState<string>("");
    const [replyType, setReplyType] = useState<number>(1);
    //const SERVER_ADDRESS = "http://localhost:8000";

    useEffect(() => {
        fetchPreviousSession();
    }, [])

    useEffect(() => {
        if (!session_id || !secret_key) return;
        fetchMessages();
        const interval = setInterval(fetchMessages, 5000);
        return () => clearInterval(interval);
    }, [session_id, secret_key, last_id]);

    const fetchMessages = () => {
        new ChatAPIs().list_guest(session_id, secret_key, last_id).then((response) => {
            if (!ChatAPIs.hasError(response)) {
                let new_last_id = last_id;
                response.messages.forEach(message => {
                    if(!new_last_id) new_last_id = message.id;
                    if (new_last_id && message.id > new_last_id) new_last_id = message.id;
                    if (message.from_guest === '0') addResponseMessage(message.message);
                })
                setLastId(new_last_id);
            }
        });
    }

    const fetchPreviousSession = () => {
        new ChatAPIs().fetch_session().then((response) => {
            if (!ChatAPIs.hasError(response) && response.id && response.secret_key) {
                setSessionID(response.id);
                setSecretKey(response.secret_key);
                setReplyType(3);
                addResponseMessage("Welcome back " + response.name + '. How can we help you today?');
            } else {
                addResponseMessage("Please enter your email address");
            }
        });
    }

    const handleNewUserMessage = (responseMessage: any) => {
        if (replyType === 1) {
            let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (!reg.test(responseMessage)) {
                addResponseMessage("Uh oh! That doesn't look like an email address.");
                setTimeout(() => addResponseMessage("Please enter a valid email address"), 500);
            } else {
                setEmail(responseMessage);
                setReplyType(2);
                addResponseMessage("Please enter your name");
            }
        } else if (replyType === 2) {
            new ChatAPIs().create_session(email, responseMessage).then((response) => {
                if (ChatAPIs.hasError(response)) {
                    setReplyType(1);
                    addResponseMessage("Couldn't connect to chat server. Please try again.");
                    addResponseMessage("Please enter your email address");
                    return;
                } else {
                    if (response.id && response.secret_key) {
                        setSessionID(response.id);
                        setSecretKey(response.secret_key);
                        setReplyType(3);
                        addResponseMessage("Welcome " + response.name + '. How can we help you today?');
                    }
                }
            });
        } else if (replyType === 3) {
            new ChatAPIs().store_message(session_id, secret_key, responseMessage).then((res) => {
                if (ChatAPIs.hasError(res)) {
                    return;
                }
            });
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <p>
                    Emailwish Chat Beta
                </p>
            </header>

            <Widget handleNewUserMessage={handleNewUserMessage}
                    title={"Welcome"}
                    subtitle={"Subtitle"}/>

        </div>
    );
}

export default App;
